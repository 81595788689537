import {
  GET_USER,
  NO_USER_FOUND,
  IS_LOADING_REQUEST,
  SIGN_IN_SUCCESS,
  SET_CONVERSATIONS,
  SET_SUBSCRIBED_ON_CONVERSATION,
} from '../actions/types';

import {User, Userconversation} from '../../graphql/API';
import {FetchUserAttributesOutput} from 'aws-amplify/auth';

export type UserState = {
  user: FetchUserAttributesOutput | null;
  currentUser: User | null;
  isLoggedIn: boolean;
  isLoadingRequest: boolean;
  error: null | any;

  conversations: Array<Userconversation>;
  subscribedConversationIds: Array<string>;
};

export const initialState: UserState = {
  user: null,
  isLoggedIn: false,
  currentUser: null,
  isLoadingRequest: false,
  error: null,

  conversations: [],
  subscribedConversationIds: [],
};

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };

    case GET_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case NO_USER_FOUND:
      return {
        ...state,
        user: null,
        error: null,
        isLoggedIn: false,
      };

    case IS_LOADING_REQUEST: {
      return {
        ...state,
        isLoadingRequest: action.payload.isLoading,
        error: action.payload.error,
      };
    }

    case SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: action.payload,
      };
    }

    case SET_SUBSCRIBED_ON_CONVERSATION: {
      return {
        ...state,
        subscribedConversationIds: action.payload,
      };
    }
    default:
      return state;
  }
};

export default user;
