import {useEffect, useState, useRef, createRef} from 'react';

import {useHistory, useLocation, useParams} from 'react-router-dom';

import './PDF.css';

export const PDFView = () => {
  const history = useHistory();
  console.log('location.pathname', history.location.state);

  //@ts-ignore
  const [pdfUrl, setPdfUrl] = useState<any>();
  const iframeRef = useRef(null);

  useEffect(() => {
    console.log('iframeRef', iframeRef.current);
  }, [iframeRef]);

  useEffect(() => {
    //@ts-ignore
    if (history.location.state?.pdfUrl) {
      //@ts-ignore
      let url: string = history.location.state?.pdfUrl;
      const urlSplitter = url?.split('?x-amz-content');
      console.log('urlSplitter', urlSplitter);
      url = urlSplitter[0];
      url = url.replaceAll('%20', ' ');
      url = url.replaceAll('%26', '&');
      url = url.replaceAll('%3A', ':');
      console.log('dfdsffffff', url + '?x-amz-content' + urlSplitter[1]);
      setPdfUrl(url + '?x-amz-content' + urlSplitter[1]);
    }
    return () => {};
  }, []);
  return (
    <div
      style={{width: '100%', height: '100%'}}
      onContextMenu={(e) => {
        e.preventDefault();
      }}>
      {
        //@ts-ignore
        history.location.state?.videoUrl ? (
          <video
            //@ts-ignore
            src={history.location.state?.videoUrl}
            controls
            controlsList="nodownload"
            width={'100%'}
            style={{height: '87%'}}
          />
        ) : (
          pdfUrl && (
            <div className="embedded-content-container">
              <embed
                //@ts-ignore
                controls="false"
                ref={iframeRef}
                controlsList="nodownload"
                src={pdfUrl + '#toolbar=0'}
                style={{width: '100%', height: '100%'}}
              />
            </div>
          )
        )
      }
    </div>
  );
};

export default PDFView;
